






import Vue from 'vue'
// import HomePage from '@/components/HomePage.vue' // @ is an alias to /src
import Impressumcmp from '@/components/Impressumcmp.vue' // @ is an alias to /src

export default Vue.extend({
  name: 'Impressum',
  components: {
    Impressumcmp
  }
})
