<template>
  <v-container class="container">
    <v-row class="text-center">
        <h1 class="content_top_header">
          Autoentsorgung München und Umgebung - kein Teile Verkauf!
        </h1>
                    <div class="form-top">
                <div class="col-md-12 col-sm-12 col-xs-12 text_intro">
                    <div class="paddingLeft0 xpaddingRight0 col-md-7 col-sm-7 col-xs-12">
                        Sie möchten Ihr <strong>Auto  verschrotten</strong> und abholen lassen?
                        Dann vereinbaren Sie ganz einfach einen Termin mit uns und wir entsorgen Ihr Auto fachgerecht
                        inklusive Abholung. Unsere <strong>sichere Autoverwertung</strong> kümmert sich
                        zuverlässig um
                        den Abtransport Ihres Altautos direkt von Ihrer Haustür. Für die fach- und
                        umweltgerechte Entsorgung
                        und Verschrottung Ihres Autos sorgen wir mit unserem Service.
                    </div>
                    <div class="col-md-5 col-sm-5 col-xs-12 img_right_top">
                        <img alt="Autoverschrottung bundesweit mit Nachweis" src="..\assets\1SeatAbschleppen.jpg" border="0">
                    </div>
                </div>
                <div class="cleaner"></div>
            </div>
            <div>
            <form  class="form_subs"
                ref="form"
                @submit.prevent="submit"
            >
                <div class="form-body compact-form ">
                    <div class="modal-auto-title">Ihre Kontaktdaten:</div>
                <div class="cleaner"/>
                        <div class="form-group paddingRight0 xpaddingBottom8 xpaddingLeft0 xpaddingRight15 col-md-2 col-sm-2 col-xs-12">
                                <v-select
                                v-model="item"
                                :items="items"
                                label="Anrede"
                                outlined
                                ></v-select>
                        </div>
                        <div class="form-group form-box xpaddingLeft0 col-md-4 col-sm-4 col-xs-12">
                            <v-text-field
                                hint="Vorname"
                                v-model="defaultForm.Vorname"
                                label="Vorname"
                                outlined
                                clearable
                                placeholder="Vorname"
                            ></v-text-field>
                        </div>
                        <div class="form-group form-box paddingLeft0 col-md-6 col-sm-6 col-xs-12">
                            <v-text-field
                                hint="Nachname"
                                v-model="defaultForm.Nachname"
                                label="Nachname"
                                outlined
                                clearable
                                placeholder="Nachname"
                            ></v-text-field>
                        </div>
                        <div class="form-group form-box xpaddingLeft0 col-md-6 col-sm-6 col-xs-12">
                            <v-text-field
                                hint="E-Mail"
                                v-model="defaultForm.EMail"
                                label="E-Mail"
                                outlined
                                clearable
                                placeholder="E-Mail"
                            ></v-text-field>
                        </div>
                        <div class="form-group form-box paddingLeft0 col-md-6 col-sm-6 col-xs-12">
                                <v-text-field
                                hint="Telefonnummer"
                                v-model="defaultForm.Telfonnummer"
                                label="Telefonnummer"
                                outlined
                                clearable
                                placeholder="Telefonnummer"
                            ></v-text-field>
                        </div>
                        <div class="form-group form-box paddingBottom15 paddingRight0 col-md-4 col-sm-4 col-xs-12">
                            <v-text-field
                                hint="Marke"
                                v-model="defaultForm.Marke"
                                label="Marke"
                                outlined
                                clearable
                                placeholder="Marke"
                            ></v-text-field>
                        </div>
                        <div class="form-group form-box paddingRight0 xpaddingRight15 col-md-4 col-sm-4 col-xs-12">
                                <v-text-field
                                hint="Modell"
                                v-model="defaultForm.Modell"
                                label="Modell"
                                outlined
                                clearable
                                placeholder="Modell"
                            ></v-text-field>
                        </div>
                        <div class="form-box paddingBottom15 col-md-4 col-sm-4 col-xs-12">
                               <v-text-field
                                hint="Baujahr"
                                v-model="defaultForm.Baujahr"
                                label="Baujahr"
                                outlined
                                clearable
                                placeholder="Baujahr"
                            ></v-text-field>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <v-textarea
                                outlined
                                name="input-7-4"
                                label="Fügen Sie hier bitte Straße, PLZ und Ort ein, wo Ihr Fahrzeug zur Abholung bereit steht. Sollten Sie diese Daten nicht vollständig zur Hand haben, beschreiben Sie kurz wo das Fahrzeug steht."
                                v-model="defaultForm.Anmerkung"
                                ></v-textarea>
                        </div>
                    <div class="cleaner "></div>
                    <!-- CHECKBOX FÜR DIE AGBS ETC... -->
                    <div class="form-group form-box paddingBottom15 paddingRight0 col-md-4 col-sm-4 col-xs-12">
                <v-checkbox
                v-model="form.terms"
                color="green"
                >
                <template v-slot:label>
                <div @click.stop="">
                  Ich akzeptiere die
                  <v-btn
                    color="blue"
                    text
                    router-link :to="{ path: '/datenschutzbestimmung' }"
                  >Datenschutzbestimmungen.</v-btn>
                </div>
                </template>
                </v-checkbox>
                    </div>
                <div class="cleaner"></div>
                </div>
                <div class="cleaner"></div>
                <div class="form-bottom col-md-12 col-sm-12 col-xs-12">
                    <div class="start_text_benefits paddingRight0 col-md-7 col-sm-12 col-xs-12">
                        <div class="benefits-title">Auto in 3 Schritten gratis verschrotten</div>
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            <div class="benefits_points">
                                <span class="formnumbers">1.</span><strong>Anfrage</strong> <br>absenden
                                <img class="form-arrow1" src="..\assets\1form-arrow.png">
                            </div>
                        </div>
                        <div class="paddingRight0 col-md-4 col-sm-4 col-xs-4">
                            <div class="benefits_points">
                             <span class="formnumbers">2.</span><strong>Rückruf</strong> <br>erhalten
                                <img class="form-arrow2" src="..\assets\1form-arrow.png">
                        </div>
                    </div>
                    <div class="paddingRight0 col-md-4 col-sm-4 col-xs-4">
                        <div class="benefits_points marginRight0">
                            <span class="formnumbers">3.</span><strong>Abholtermin</strong> <br>vereinbaren
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-12 col-xs-12 borderLeftLight textcenter">
                    <button type="button" @click="submit" class="btn btn-default form-button">
                        Anfrage absenden
                    </button>
                </div>
                </div>
                <div class="cleaner"></div>
             </form>
              <v-snackbar
              color="red"
                v-model="snackbar"
                :multi-line="multiLine"
              >
                {{ text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="black"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <div class="cleaner"></div>
                        <div class="col-md-9 col-sm-8 col-xs-12 noposition paddingLeft0">
                <div>
                    <div>
                <span class="content_top_text">
                    <div class="img_left">
                        <img alt="Kostenlose Hotline von Süd-Automobile" src="..\assets\1UnfallGoflICabrio.jpg" border="0">
                    </div>
                    <h2 style="text-align: center; color: #1E88E5;">Autoverschrottung inklusive Abholung</h2>
                    Autoentsorgung München und Umgebung mit Verwertungsnachweis. Lassen Sie Ihr Altfahrzeug bequem
                    von uns abholen und entsorgen. Unsere zertifizierten Autoverwertungen
                    kümmern sich in ganz München und Umgebung um die fachgerechte Verwertung und Verschrottung
                    Ihres Altautos inklusive Abholung direkt von Ihrer Haustür. Ihr Altauto wird fach-
                    und umweltgerecht entsorgt und Sie bekommen von uns einen Verwertungsnachweis ausgestellt.<br>
                    <br>
                    <br>
                    <br>
                    <h3 style="text-align: center; color: #1E88E5;">Autoentsorgung</h3>
                    Seit 2018 bieten wir Ihnen unseren Bayernweiten Entsorgungsservice für
                    Autoverwertung an. Nutzen Sie unseren Service zur Altfahrzeugrücknahme
                    Sie sparen sich den Anfahrtsweg, denn wir holen Ihr Altauto
                    direkt bei Ihnen ab. Neben der Autoverschrottung bieten
                    wir zudem noch den München und Umgebung weiten Service der Motorradentsorgung an!
                    <br>
                    <br>
                    <br>
                    <div class="img_left">
                        <img alt="Kostenlos Auto verschrotten" src="..\assets\Unfall Golf.jpg" border="0">
                    </div>
                    <h3 style="text-align: center; color: #1E88E5;">Auto abholen und verschrotten lassen</h3>
                    Sie wollen Ihr Auto abholen und entsorgen lassen oder haben Fragen zum Thema Auto
                    entsorgen oder Autoverwertung? Wir helfen Ihnen gerne weiter. Wählen Sie dazu einfach
                    unsere kostenlose Hotline <b>015111775416</b> oder senden Sie uns über das obere Formular
                    eine Anfrage zur Abholung und Entsorgung Ihres Autos und wir melden uns bei
                    Ihnen zurück.<br>
                    <br>
                    <br>
                    <br>
                    <h3 style="text-align: center; color: #1E88E5;">Fach- und umweltgerechte Autoverwertung</h3>
                    Unser Service zur Autoentsorgung und Autoverschrottung steht Ihnen in Bayern, München, Markt Schwaben,
                    Dachau, Ebersberg, Aßling, Taufkirchen, Starnberg, Roßenheim, Vaterstetten,
                    Poing, Erding, Mossing, Haar, Aying. Wenn Sie Ihr Auto nicht selbst zur Autoverschrottung in unserer
                    Niederlassunge vorbei bringen können oder wollen, können wir
                    Ihr Auto mit unserem Abschleppfahrzeug direkt von Ihnen zu Hause abholen.<br>
                    <br>
                    <br>
                    <br>
                    <div class="img_bottom"><img alt="Kostenlos Auto entsorgen" src="..\assets\Probe.jpg" border="10">
                    </div>
                    <h3 style="color: #1E88E5;">Jetzt Abholtermin vereinbaren!</h3>
                    Für eine Autoverschrottung rufen Sie uns einfach
                    an unter  <b>015111775416</b> oder klicken Sie unten auf den WhatsApp Button und füllen Sie
                    bitte kurz das Formular aus und wir rufen Sie dann wegen eines Abholtermins zurück! Sie
                    können dann einen Wunschtermin für die Abholung des Altautos vereinbaren.
                </span>
                    </div>
                    <div class="cleaner"></div>
                </div>
            </div>
      <div class="col-md-3 col-sm-4 col-xs-3 paddingLeft40 paddingRight0">
         <div class="cleaner"></div>

                        <div>
                    <div class="box_header">Unsere Services</div>
                    <ul>
                        <li><a href="/">Autoverschrottung</a></li>
                    </ul>
                    <ul>
                        <li>Weitere Leistungsorte</li>
                        <li>Autoentsorgung München</li>
                        <li>Autoentsorgung Bayern</li>
                        <li>Autoentsorgung Mart-Schwaben</li>
                        <li>Autoentsorgung Dachau</li>
                        <li>Autoentsorgung Ebersberg</li>
                        <li>Autoentsorgung Aßling</li>
                        <li>Autoentsorgung Taufkirchen</li>
                        <li>Autoentsorgung Starnberg</li>
                        <li>Autoentsorgung Rosenheim</li>
                        <li>Autoentsorgung Vaterstetten</li>
                        <li>etc...</li>
                    </ul>
                </div>
            </div>
        <v-col
        class="mb-5"
        cols="12"
        >
<p>
<h1 style="text-align: center; color: #1E88E5;">Sie haben Fragen zu unserem Service?<br>
Dann Schicken Sie uns einfach eine WhatsApp
</h1>
<div style="justify-content:center;  display:flex;">
<a  style="justify-content:center;" href="https://api.whatsapp.com/send?phone=4915111775416&text=Ich+bin+sehr+daran+interessiert+mein+Auto+zu+entsorgen&app_absent=0" title="Senden Sie uns Nachricht über WhatsApp" target="_blank" rel="noreferrer noopener">
    <div style="background-color: #25D365; border-radius:35px; height:120px; width:120px; color: #ffffff;">
    <svg style="width:72px; height:72px; margin:24px 24px; overflow:hidden; fill:currentColor !important;">
      <use href="#whatsapp" style="box-sizing: border-box;">
    <symbol id="whatsapp" viewBox="0 0 24 24">
      <path fill="#fff" d="M3.516 3.516c4.686-4.686 12.284-4.686 16.97 0 4.686 4.686 4.686 12.283 0 16.97a12.004 12.004 0 01-13.754 2.299l-5.814.735a.392.392 0 01-.438-.44l.748-5.788A12.002 12.002 0 013.517 3.517zm3.61 17.043l.3.158a9.846 9.846 0 0011.534-1.758c3.843-3.843 3.843-10.074 0-13.918-3.843-3.843-10.075-3.843-13.918 0a9.846 9.846 0 00-1.747 11.554l.16.303-.51 3.942a.196.196 0 00.219.22l3.961-.501zm6.534-7.003l-.933 1.164a9.843 9.843 0 01-3.497-3.495l1.166-.933a.792.792 0 00.23-.94L9.561 6.96a.793.793 0 00-.924-.445 1291.6 1291.6 0 00-2.023.524.797.797 0 00-.588.88 11.754 11.754 0 0010.005 10.005.797.797 0 00.88-.587l.525-2.023a.793.793 0 00-.445-.923L14.6 13.327a.792.792 0 00-.94.23z"></path>
    </symbol>
      </use>
    </svg>
    </div>
    </a>
</div>
<p style="font-size:xx-small;">Mit den klicken auf dem WhatsApp Button, erklären Sie sich damit einverstanden, das Ihre Daten (insbesondere Telefonnummer und Name) an Facebook und WhatsApp weitergegeben werden. WhatsApp Inc. teilt Informationen weltweit, sowohl intern mit den Facebook-Unternehmen als auch extern mit Unternehmen, Dienstleistern und Partnern und außerdem mit jenen, mit denen du weltweit kommunizierst. Deine Informationen können für die in dieser Datenschutzrichtlinie https://www.whatsapp.com/legal/#privacy-policy beschriebenen Zwecke beispielsweise in die USA oder andere Drittländer übertragen oder übermittelt bzw. dort gespeichert und verarbeitet werden.</p>
      </v-col>
    </v-row>
    <!-- <a class="delegar" href="https://api.whatsapp.com/send?phone=4915111775416&text=Ich+bin+sehr+daran+interessiert+mein+Auto+zu+entsorgen&app_absent=0" title="Senden Sie uns Nachricht über WhatsApp" target="_blank" rel="noreferrer noopener"><img src="..\assets\Whatsapp.png" alt="WhatsApp" border="0" width="100" height="70">
    </a>-->
    <a class="delegar" href="https://api.whatsapp.com/send?phone=4915111775416&text=Ich+bin+sehr+daran+interessiert+mein+Auto+zu+entsorgen&app_absent=0" title="Senden Sie uns Nachricht über WhatsApp" target="_blank" rel="noreferrer noopener">
    <div class="delegar" style="background-color: #25D365; border-radius:35px; height:60px; width:60px; color: #ffffff;">
    <svg style="width:36px; height:36px; margin:12px 12px; overflow:hidden; fill:currentColor !important;">
      <use href="#whatsapp" style="box-sizing: border-box;">
    <symbol id="whatsapp" viewBox="0 0 24 24">
      <path fill="#fff" d="M3.516 3.516c4.686-4.686 12.284-4.686 16.97 0 4.686 4.686 4.686 12.283 0 16.97a12.004 12.004 0 01-13.754 2.299l-5.814.735a.392.392 0 01-.438-.44l.748-5.788A12.002 12.002 0 013.517 3.517zm3.61 17.043l.3.158a9.846 9.846 0 0011.534-1.758c3.843-3.843 3.843-10.074 0-13.918-3.843-3.843-10.075-3.843-13.918 0a9.846 9.846 0 00-1.747 11.554l.16.303-.51 3.942a.196.196 0 00.219.22l3.961-.501zm6.534-7.003l-.933 1.164a9.843 9.843 0 01-3.497-3.495l1.166-.933a.792.792 0 00.23-.94L9.561 6.96a.793.793 0 00-.924-.445 1291.6 1291.6 0 00-2.023.524.797.797 0 00-.588.88 11.754 11.754 0 0010.005 10.005.797.797 0 00.88-.587l.525-2.023a.793.793 0 00-.445-.923L14.6 13.327a.792.792 0 00-.94.23z"></path>
    </symbol>
      </use>
    </svg>
    </div>
    </a>
  </v-container>
</template>
<script>
export default {
  data () {
    const defaultForm = Object({
      Vorname: '',
      Nachname: '',
      EMail: '',
      Telfonnummer: '',
      Marke: '',
      Modell: '',
      Baujahr: '',
      Anmerkung: '',
      terms: false
    })

    return {
      multiLine: true,
      text: 'Sie müssen die AGBs Akzeptieren!',
      form: Object.assign({}, defaultForm),
      rules: {
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        animal: [val => (val || '').length > 0 || 'This field is required'],
        name: [val => (val || '').length > 0 || 'This field is required']
      },
      items: ['Herr', 'Frau'],
      conditions: false,
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.',
      snackbar: false,
      terms: false,
      defaultForm
    }
  },

  computed: {
    formIsValid () {
      return (
        this.form.Vorname &&
          this.form.Nachname &&
          this.form.EMail &&
          this.form.Telfonnummer &&
          this.form.terms
      )
    }
  },

  methods: {
    resetForm () {
      this.form = Object.assign({}, this.defaultForm)
      this.$refs.form.reset()
    },
    submit () {
      if (this.form.terms === false) {
        this.snackbar = true
      } else {
        window.open('https://api.whatsapp.com/send?phone=4915111775416&text=Ich+' + this.item + '+' + this.defaultForm.Vorname + this.defaultForm.Nachname + '+bin+sehr+daran+interessiert+mein+Auto+' + this.defaultForm.Marke + '+' + this.defaultForm.Modell + '+' + this.defaultForm.Baujahr + '+zu+entsorgen+Meine+TelefonNr+' + this.defaultForm.Telfonnummer + '+Meine+E-Mail+' + this.defaultForm.EMail + '+noch+eine+Anmerkung:+' + this.defaultForm.Anmerkung + '&app_absent=0')
      // this.resetForm()
      }
    }
  }
}
</script>
