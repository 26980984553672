






import Vue from 'vue'
// import HomePage from '@/components/HomePage.vue' // @ is an alias to /src
import Autoentsorgungcmp from '@/components/Autoentsorgungcmp.vue' // @ is an alias to /src

export default Vue.extend({
  name: 'Autoentsorgung',
  components: {
    Autoentsorgungcmp
  }
})
