






import Vue from 'vue'
// import HomePage from '@/components/HomePage.vue' // @ is an alias to /src
import DatenschutzbestimmungCmp from '@/components/DatenschutzbestimmungCmp.vue' // @ is an alias to /src

export default Vue.extend({
  name: 'Datenschutzbestimmungen',
  components: {
    DatenschutzbestimmungCmp
  }
})
